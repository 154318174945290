
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'
import UserState, { IUserState } from '../store/modules/users';
import TierState, { ITierState } from '../store/modules/tier';

let userState = {} as IUserState;
let tierState = {} as ITierState;

export default defineComponent({
    name: 'SubscriptionStatusProgress',
    data() {
        return {
            maxReceivers: 0
        }
    },
    props: ['dashboardMode'],

    computed: {
        formattedSubscriptionStatusText() {
            const receiverCount = (this as any).subscriptionStatus ? (this as any).subscriptionStatus.currentActiveDeviceCount : 0;
            return `✓ ${receiverCount} active ${(this as any).subscriptionStatus?.type != 'Trial' ? '' : ''}receiver${ receiverCount == 1 ? "" : "s" }`;
        },

        percentage(): number {
            if(!this.subscriptionStatus) return 0;
            if(this.subscriptionStatus.subscriptionLimit == 0) return 0;  
            return (this.subscriptionStatus.currentActiveDeviceCount / this.subscriptionStatus.subscriptionLimit)*100;
        },
        receiverMessage(): string {
            const receiverCount = this.subscriptionStatus ? this.subscriptionStatus?.subscriptionAvailability : 0;
            let receiverCountMessage = '';

            if(this.subscriptionStatus?.type == 'Trial') {
                let maxReceiverText = '';
                if(this.maxReceivers == -1) {
                    maxReceiverText = '0';
                }
                else if(this.maxReceivers == 0) {
                    maxReceiverText = 'unlimited';
                }
                else {
                    maxReceiverText = this.maxReceivers.toString();
                }

                receiverCountMessage = 'Add ' + maxReceiverText + ' receivers during your trial.';
            }
            else {
                receiverCountMessage = `${JSON.stringify(receiverCount)} subscribed receiver${receiverCount == 1 ? '' : 's'} remaining`;
            }

            return receiverCountMessage;
        },
        subscriptionStatus(): any {
            return userState.subscriptionStatus;
        }
    },
    async created () {
        userState = getModule(UserState);
        tierState = getModule(TierState);

        await userState.fetchSubscriptionStatus();

        if(userState.me && !userState.me.superUser) {
            const tier = await tierState.fetchTier();
            if(tier && tier.data) {
                this.maxReceivers = tier.data.restrictionReceivers ?? 0;
            }
        }
        else {
            this.maxReceivers = 0;
        }

        

    }
});
