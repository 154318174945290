
import { defineComponent, nextTick, computed } from 'vue';
import { mdiArrowCollapseLeft, mdiArrowCollapseRight, mdiChevronRight, mdiHelp, mdiChevronDown, mdiAccount, mdiBullhorn } from '@mdi/js';
import UserState, { IUserState } from './store/modules/users';
import { IUser } from './store/interfaces/IUser';
import { IOrganization } from './store/interfaces/IOrganization';
import { getModule } from 'vuex-module-decorators';
import ExpiredSubscrption from './components/ExpiredSubscrption.vue';
import Onboarding from './components/Onboarding.vue';

import OnboardStatusState, { IOnboardStatusState } from './store/modules/onboarding';
import RoleState, { IRoleState } from './store/modules/role';
import Icon from './components/Icon.vue';

let onboardStatusState = {} as IOnboardStatusState;
let roleState = {} as IRoleState;
let userState = {} as IUserState;

export default defineComponent({
    components: { 
      ExpiredSubscrption,
      Onboarding,
      Icon
    },
    name: 'Settings Portal',

    data() {
        return {
            mdiArrowCollapseLeft,
            mdiArrowCollapseRight,
            mdiChevronRight,
            mdiHelp,
            mdiBullhorn,
            mdiAccount,
            mdiChevronDown,
            navCollapsed: false,
            orgTooltipDisabled: false,
            organizationSubNavExpanded: false,
            org: {} as IOrganization,
            resizeEventThrottle: false,
            windowWidth: 0,
            mobileNavToggled: false,
            shadowedNav: false,
            sideNavReference: {} as any,
            accountPopoverEnabled: false as boolean,
            organizationPopoverEnabled: false as boolean,
            hideOnboardingOnMobile: true as boolean,
            hideOnboardingByAccess: true as boolean,
            hasReceiversAccess: true as boolean,
            hasRoomsAccess: true as boolean,
            hasLocationsAccess: true as boolean,
            hasSignageAccess: true as boolean,
            hasAlertsAccess: true as boolean,
            hasOrganizationAccess: true as boolean,
            hasBillingAccess: true as boolean,
            hasUsersAccess: true as boolean,
            hasSuperAccess: false as boolean
        }
    },
  
    computed: {

        steps() {
            return onboardStatusState.stepInstructions
        },

        currentStep() {
            return onboardStatusState.currentStep;
        },        

        currentStepNumber() {
            let progressStepNumber;
            switch (onboardStatusState.currentStep.category) {
                case 'Overview':
                    progressStepNumber = 1;
                    break;

                 case 'Receivers':
                    progressStepNumber = 2;
                    break;

                 case 'Rooms':
                    progressStepNumber = 3;
                    break;

                 case 'Finish':
                    progressStepNumber = 4;
                    break;
            
                default:
                    progressStepNumber = 1;
                    break;
            }
            return progressStepNumber;
        },

        userOnboardStatusProp() {
            return onboardStatusState.userOnboardStatus;
        },

        me(): IUser | undefined {
            return userState.me;
        },

        isAdmin(): boolean {
            return roleState.isAdmin;
        },

        navCollapsedAuto(): boolean {
            return this.navCollapsed || this.windowWidth < 992;
        },

        roomSignMode (): any {
            return this.$route.path.includes('background') || this.$route.path.includes('instructions');
        },

        orgName (): string | null {
            if(!this.me || !this.me.organization) return null;
            return this.me.organization.name;
        },

        signupMode(): any {
            if(this.$route.path.includes('signup')) return true;
            if(this.$route.path.includes('error')) return true;
            // if(this.$route.path.includes('template')) return true;
            if(this.roomSignMode) return false;
            return false;
        },

        showExpiredSubscriptionModal (): boolean {
            // Don't show expired subscription modal on user swap page 
            if(this.$route.path.includes('users/swap')) {
                return false;
            }

            if(!this.$route.path.includes('organization/billing')) {
                return true;
            }
    
            return false;
        },

        currentHost(): string {
            return `${window.location.protocol}//${window.location.hostname}/`;
        }
    },
    
    provide() {
        return {
            windowWidth: computed(() => this.windowWidth),
            currentStep: computed(() => this.currentStep),
            userOnboardStatus: computed(() => this.userOnboardStatusProp),
            hideOnboardingOnMobile: computed(() => this.hideOnboardingOnMobile),
            hideOnboardingByAccess: computed(() => this.hideOnboardingByAccess)
        }
    },

    async created() {

        // Window resize events for collapsed side-nav & sub-navs
        this.windowWidth = window.innerWidth;
        window.addEventListener("resize", this.resizeEventHandler);
        // Window resize events for collapsed side-nav & sub-navs
        this.navCollapsed = window.localStorage.getItem('dt-nav-collapse') == "true" ? true : false;
        this.organizationSubNavExpanded = window.localStorage.getItem('dt-org-subnav-expanded') == "true" ? true : false;

        if(!this.signupMode) {
            try {
                userState = getModule(UserState);
                onboardStatusState = getModule(OnboardStatusState);
                roleState = getModule(RoleState);

                const user = await userState.fetchMe(null);
                
                if(user.success) {
                    await Promise.all([
                        onboardStatusState.fetchOnboardStatus(),
                        roleState.fetchUserRole()
                    ]);
                    if(userState.me) {
                        this.org = userState.me.organization;
                    }
                    
                    this.hasSuperAccess = roleState.hasSuperAccess;
                    this.hasReceiversAccess = roleState.hasReceiversAccess;
                    this.hasRoomsAccess = roleState.hasRoomsAccess;
                    this.hasLocationsAccess = roleState.hasLocationsAccess;
                    this.hasSignageAccess = roleState.hasSignageAccess;
                    this.hasAlertsAccess = roleState.hasAlertsAccess;
                    this.hasOrganizationAccess = roleState.hasOrganizationAccess;
                    this.hasBillingAccess = roleState.hasBillingAccess;
                    this.hasUsersAccess = roleState.hasUsersAccess;
                    this.hideOnboardingByAccess = roleState.hasSuperAccess || !(this.hasReceiversAccess && this.hasRoomsAccess);
                    if(this.hideOnboardingByAccess || userState?.me?.email.endsWith('+support@airsquirrels.com')) {
                        await onboardStatusState.hide();
                        await onboardStatusState.fetchOnboardStatus(true);
                    }
                }
            }
            catch(e:any) {
                // don't care. Its very possible were not auth'd and we don't have "me"
            }
        }
        
        // We need to await nextTick so the nav is actually rendered
        await nextTick;
        this.sideNavReference = (this as any).$refs['sideNav'];
        this.sideNavReference.addEventListener("scroll", this.subNavScrollHandler);
        this.updateNavShadow();

        if(!this.signupMode) {
            onboardStatusState = getModule(OnboardStatusState);
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.toggleOnboardingOnScreenResize();

            window.addEventListener('resize', this.toggleOnboardingOnScreenResize);
        });
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.toggleOnboardingOnScreenResize);
    },

    methods: {
        onStartOnboarding() {
            if(!this.hideOnboardingByAccess) {
                onboardStatusState.start();
            }
        },

        toggleOnboardingOnScreenResize() {
            if (window.innerWidth <= 900) {
                this.hideOnboardingOnMobile = true;

                onboardStatusState.dismiss();
            } else {
                this.hideOnboardingOnMobile = false;
            }
        },

        toggleMobileNavHandler() {
            this.mobileNavToggled = !this.mobileNavToggled;
        },

        updateNavShadow() {
            let amountScrolled = this.sideNavReference.clientHeight + this.sideNavReference.scrollTop;
            if(amountScrolled < this.sideNavReference.scrollHeight) {
                this.shadowedNav = true;
            } else {
                this.shadowedNav = false;
            }
        },
        
        resizeEventHandler() {
            /* --
            Keep 66ms throttle for a 15 frames per second refresh (1000/15)
            Keeps resize event performant while still feeling responsive.
            -- */
            const throttle = 66;
            if (!this.resizeEventThrottle) {
                this.updateNavShadow();
                this.windowWidth = window.innerWidth;
                this.resizeEventThrottle = true;
                setTimeout(()=>{
                    this.updateNavShadow();
                    this.resizeEventThrottle = false;
                    this.windowWidth = window.innerWidth;
                }, throttle);
            }  
        },

        subNavScrollHandler() {
            window.requestAnimationFrame(()=> {
                this.updateNavShadow();
            })
        },

        async orgSubNavToggleHandler() {
            if(!this.navCollapsedAuto) {
                const newExpandedStateValue = !this.organizationSubNavExpanded;
                const newExpandedStateString = newExpandedStateValue.toString();
                window.localStorage.setItem('dt-org-subnav-expanded', newExpandedStateString);
                this.organizationSubNavExpanded = newExpandedStateValue;
            }
        },

        async navCollapseHandler() {
            const currentCollapseState = this.navCollapsed;
            if(currentCollapseState == true) {
                window.localStorage.setItem('dt-nav-collapse', 'false');
                this.navCollapsed = false;
            } else {
                window.localStorage.setItem('dt-nav-collapse', 'true');
                this.navCollapsed = true;
            }
            await nextTick;
            this.updateNavShadow();
        },

        async receiverNavigationClick() {
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog && this.currentStep.guideStepId == 'receiversClickReceivers') {
                await onboardStatusState.nextStep();
            }

            this.toggleMobileNavHandler();
        },
        async roomNavigationClick() {
            if(onboardStatusState && onboardStatusState.userOnboardStatus && onboardStatusState.userOnboardStatus.isOnboarding && onboardStatusState.userOnboardStatus.showDialog && this.currentStep.guideStepId == 'roomsClickRooms') {
                await onboardStatusState.nextStep();
            }

            this.toggleMobileNavHandler();
        },

        onReleaseClick() {
            // Update user to turn off notification dot
            if(this.me && this.me.showReleaseNotification) {
                this.me.showReleaseNotification = false;
                userState.updateMe(this.me);
            }
           
            // Open new tab to hubspot page
            window.open('https://www.airsquirrels.com/ditto-portal/updates', '_blank')?.focus();
        }
    }
});
