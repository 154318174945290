
import { getAuthInstance } from '../../auth';
import { defineComponent } from 'vue';
import { getModule } from 'vuex-module-decorators'
import UserState, { IUserState } from '../../store/modules/users';

import { IUser } from '../../store/interfaces/IUser';
import { IUserCreateModel } from '../../store/interfaces/IUserCreateModel';

import { mdiAlertCircleOutline, mdiRefresh } from '@mdi/js';
let userState = {} as IUserState;

import { IOnboardStatus } from '../../store/interfaces/IOnboardingStatus';
import OnboardStatusState, { IOnboardStatusState } from '../../store/modules/onboarding';
import PageDirtyService from '../../services/PageDirtyService';
import RoleState, { IRoleState } from '../../store/modules/role';
import { IRole } from '../../store/interfaces/IRole';
import { IOnboardingUserAction } from '../../store/interfaces/IOnboardingUserAction';
import { IAuth0UserData } from '../../store/interfaces/IAuth0UserData';
import Icon from '../../components/Icon.vue';

let onboardStatusState = {} as IOnboardStatusState;
let roleState = {} as IRoleState;

let duplicateEmail = false;

export default defineComponent ({
    name: 'UserDetails',
    data() {
        var dupEmail = (rule: any, value: any, callback: any) => {
            if(!duplicateEmail) return callback();

            callback(new Error('Duplicate email. Please try a different email.'))
        };
        return {
            me: {} as IUser | undefined,
            user: {} as IUser,
            currentUserActions: null as any,
            activeOnboardingCollapse: [],
            mfaModalVisible: false,
            mdiAlertCircleOutline,
            mdiRefresh,
            loading: false as boolean,
            saving: false as boolean,
            resendingVerificationEmail: false as boolean,
            invitedByName: '' as string,
            otherUserOnboardingAccess: true as boolean,
            onboardings: [] as any,
            auth0UserData: {} as IAuth0UserData,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Please enter a name.',
                        trigger: 'blur'
                    },
                ],
                email: [
                    {
                        required: true,
                        message: 'Please enter an email address.',
                        trigger: 'blur'
                    },
                    {
                        type: 'email',
                        message: 'Please enter a valid email address.',
                        trigger: 'blur'
                    },
                    {
                        validator: dupEmail,
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    props: ["creationMode"],

    components: {
        Icon
    },

    watch: {
        "$route.params.id"(value) {
            if(value) {
                this.loadUser();
            }
        },
        user: {
            handler (e) {
                PageDirtyService.monitorObjectSet([{defaultObject: userState.userById(this.user.id), mutatedObject: e}]);
            },
            deep: true
        },
        me: {
            handler (e) {
                PageDirtyService.monitorObjectSet([{defaultObject: userState.me, mutatedObject: e}]);
            },
            deep: true
        }
    },

    computed: {
        hasActiveOnboarding() : boolean {
            if(!this.onboardings || !this.onboardings.data || this.onboardings.data.length == 0) return false;

            return (this.onboardings.data as []).some((x: IOnboardStatus)=> !x.dontShowAgain && x.isOnboarding);
        },

        userOriginalCopyMFA() : boolean {
            let originalUser = userState.userById(this.user.id) as IUser;
            if(originalUser && originalUser.MFA) {
                return originalUser.MFA;
            }
            return false;
        },

        isMe () {
            if(this && this.user) {
                return (this as any).user.id == (this as any).me.id;
            }
            return false;
        },

        isAdmin(): boolean {
            return roleState.isAdmin || roleState.hasSuperAccess;
        },

        warningHasChanged() {
            if(this.creationMode || !this.user.MFA) return false;
            return (this as any).user.MFA != (this as any).userOriginalCopyMFA;
        },

        tutorialText() {
            if(this.isMe) {
                return 'Launch the guided Ditto setup tutorial.'
            }
            else {
                return 'Launch the guided Ditto setup tutorial for this user the next time they log in.';
            }
        },

         newOnboarding() {
            let onboarding = {} as IOnboardStatus;
            onboarding.organization = this.user.organization;
            onboarding.user = this.user;
            onboarding.initiatedUser = this.me ? this.me : this.user;
            onboarding.isOnboarding = true;
            onboarding.showDialog = true;
            onboarding.guideStepId = 'overviewInitial';
            onboarding.step = 1;
            return onboarding;
        },

        roleName() {
            if(!roleState || !roleState.userRole) return "";
            return roleState.userRole.name ? roleState.userRole.name : '';
        },

        allRoles(): IRole[] {
            if(!roleState.roles) return [];

            return roleState.roles;
        },

        showOnboardingButton() {
            if(this.isMe && !(this as any).hideOnboardingByAccess.value) {
                return true;
            }
            else if(!this.isMe && this.otherUserOnboardingAccess) {
                return true;
            }
            else {
                return false;
            }
        }
    },

    inject: [
        'hideOnboardingByAccess'
    ],

    methods: {
        copyToClipboard(onboardingId: any){
            navigator.clipboard.writeText(onboardingId);
            (this as any).$message.success('Onboarding ID copied');
        },

        handleClose(done: any) {
            done();
        },

        emailChanged() {
            duplicateEmail = false;
        },

        getStepDetails(step: number): any {
            return onboardStatusState.getStepDetails(step);
        },

        getActionDetails(action: IOnboardingUserAction): any {
            const snapshot = action.onboardingSnapShot ? JSON.parse(action.onboardingSnapShot) : null;
            if(!snapshot || !snapshot.step) return null;

            return onboardStatusState.getStepDetails(snapshot.step);
        },

        async onResendVerificationEmail() {
            if(this.resendingVerificationEmail || this.saving) return;

            this.resendingVerificationEmail = true;
            const storeResponse = await userState.resendEmailVerification(this.user.id);
            
            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Verification email failed: ${storeResponse.reason}` : 'Verification email failed');
                this.resendingVerificationEmail = false;
                return;
            }

            this.resendingVerificationEmail = false;
            (this as any).$message.success('Verification email has been sent.');
        },

    
        async onDelete() {
            if(this.saving) return;

            await (this as any).$confirm('Are you sure you want to delete this user?', 'Warning', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });

            await userState.deleteUser(this.user);
            (this as any).$message.success("deleted");
            PageDirtyService.markPageClean();
            this.$router.replace({ path: '/users' });
        },

        async onResetPassword() {
            if(this.saving) return;
            
            const storeResponse = await userState.resetPassword(this.user);
            if(!storeResponse.success) {
                (this as any).$message.error(storeResponse.reason ? `Reset password email failed: ${storeResponse.reason}` : 'Reset password email failed');
                return;
            }
            (this as any).$message.success('Reset password email sent.');
        },
        
        async onSave() {
            if(!await this.validateForm() || this.saving) return;

            if(this.creationMode) {
                let newUser = {} as IUserCreateModel;
                newUser = Object.assign(newUser, this.user);
                newUser.invitedBy = this.invitedByName;
                
                this.saving = true;
                const storeResponse = await userState.createUser(newUser);

                if(!storeResponse.success) {
                    this.saving = false;
                    (this as any).$message.error(storeResponse.reason ? `User creation failed: ${storeResponse.reason}` : 'User creation failed');
                    return;
                }

                if(storeResponse.data) {
                    this.user = storeResponse.data;
                    PageDirtyService.markPageClean();
                    this.$router.replace({ path: `/users/${ storeResponse.data.id }` });
                }
                this.saving = false;
                (this as any).$message.success('User created');

            } else {
                const oldUser = Object.assign({}, userState.userById(this.$route.params.id as string) ?? {} as IUser);
                let changingEmail = false;
                let enableMFA = false;
                if(oldUser.MFA == false && this.user.MFA == true){
                    enableMFA = true;
                }
                if(this.user.email != oldUser.email) {
                    changingEmail = true;
                    try {
                        await (this as any).$confirm(`${this.isMe? 'You' : 'This user'} will be logged out. ${this.isMe? 'Click' : 'They must click'} the link in the verification email sent to ${this.isMe? 'your' : 'the'} new email address to log in.`, 'Warning', {
                            confirmButtonText: 'Update Email Address',
                            cancelButtonText: 'Cancel',
                            type: 'error'
                        });
                    }
                    catch {
                        // cancelled
                        return;
                    }
                }

                this.saving = true;
                this.user.invitedBy = this.invitedByName;
                const storeResponse = await userState.updateUser(this.user);
                if(!storeResponse.success) {
                    this.saving = false;
                    if(storeResponse.reason === 'Email already used. Please try another email.') {
                        duplicateEmail = true;
                        return this.validateForm();
                    }

                    (this as any).$message.error(storeResponse.reason ? `User update failed: ${storeResponse.reason}` : 'User update failed');
                    return;
                }
                PageDirtyService.markPageClean();
                this.saving = false;
                (this as any).$message.success('User saved');

                if((changingEmail || enableMFA) && this.isMe) {
                    this.$router.push({ path: `/logout` })
                }
            }
        },

        validateForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['userForm'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }

                    resolve(valid);
                });
            });
        },

        continueMfaModalHandler() {
            this.user.MFA = !this.user.MFA;
            this.mfaModalVisible = false;
        },

        mfaInputHandler() {
            if(!this.warningHasChanged) {
                this.mfaModalVisible = true;
            } else {
                // If we're chaning back to an original value,
                // we dont need to confirm the change.
                this.continueMfaModalHandler();
            }
        },

        onCancel() {
            this.$router.go(-1);
        },

        async loadUser () {
            const userId = this.$route.params.id as string;
            await userState.fetchUsers(userId)
            this.user = Object.assign({}, userState.userById(userId) ?? {} as IUser);
            this.me = userState.me;

            const storeResponse = await userState.fetchAuth0UserData(userId);

            console.log('storeResponse', storeResponse);

            if(storeResponse.success && storeResponse.data) {
                this.auth0UserData = storeResponse.data;
            }

            if(this.creationMode) this.roleName = 'user';
            this.loading = false;
            return;
        },

        async loadOtherUserOnboardingStatus() {
            this.onboardings = await onboardStatusState.fetchOtherUsersOnboardStatusSuper(this.user.id);
        },

        async onStartOnboardingForOtherUser() {
            if(this.hasActiveOnboarding) {
                await (this as any).$confirm('The guided Ditto setup tutorial is already in progress for this user.', 'Warning', {
                    confirmButtonText: 'Ok',
                    showCancelButton: false,
                    type: 'warning'
                });
                return;
            }
            else {
                const storeResponse = await onboardStatusState.createOnboardStatus(this.newOnboarding);
                if(!storeResponse.success) {
                    (this as any).$message.error(storeResponse.reason ? `User update failed: ${storeResponse.reason}` : 'User update failed');
                    this.loadOtherUserOnboardingStatus();
                    return;
                }

                (this as any).$message.success('The guided Ditto setup tutorial has been started for this user');
                this.loadOtherUserOnboardingStatus();
                
                return;
            }
           
        },

        async onStartOnboarding() {
            if(this.isMe) {
                await onboardStatusState.fetchOnboardStatus(true);
                if(onboardStatusState.userOnboardStatus && !onboardStatusState.userOnboardStatus.isOnboarding) {

                    const storeResponse = await onboardStatusState.createOnboardStatus(this.newOnboarding);
                    if(!storeResponse.success) {
                        (this as any).$message.error(storeResponse.reason ? `User update failed: ${storeResponse.reason}` : 'User update failed');
                        await onboardStatusState.fetchOnboardStatus(true);
                        return;
                    }

                   await onboardStatusState.fetchOnboardStatus(true);
                }

                if(!(this as any).hideOnboardingByAccess.value) {
                    onboardStatusState.start();
                }
            }
            else {
                this.onStartOnboardingForOtherUser();
            }
        },

        async onGenerateSignature(event: any) {
            if (event) event.preventDefault();
            
            this.auth0UserData.app_metadata = null;
            const userId = this.$route.params.id as string;
            const updateResponse = await userState.updateAuth0Signature(userId);

            if(!updateResponse.success) {
                (this as any).$message.error(updateResponse.reason ? `User update failed: ${updateResponse.reason}` : 'Signature re generation failed!');
                return;
            }

            const storeResponse = await userState.fetchAuth0UserData(userId);

            if(storeResponse.success && storeResponse.data) {
                this.auth0UserData = storeResponse.data;
            }

            (this as any).$message.success('Signature Generated!');
        }
    },

    async created () {
        this.loading = true;
        getAuthInstance();
        userState = getModule(UserState);
        onboardStatusState = getModule(OnboardStatusState);
        roleState = getModule(RoleState);

        await Promise.all([
            userState.fetchMe(null),
            roleState.fetchUserRole(),
        ]);

        await this.loadUser();  
        await roleState.fetchRoles(this.user.organization.id);

        if(this.me) {
            this.invitedByName = this.me.name;
        }
        if(!this.isMe) {
            this.loadOtherUserOnboardingStatus();
        }
    },
})
