import {
    Action,
    Module,
    VuexModule,
} from 'vuex-module-decorators';

import store from '../../store';

import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import moment from 'moment';


const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export interface IStatFilterOptionModel {
    option: string;
    value: string;
    idx: number;
}

export interface IStatRangeSelectionModel {
    dateRange: {rangeStart: any; rangeEnd: any};
    groupBy: string;
}

export interface IStatQueryModel {
    stats: string[];
    aggregate: 'sum' | 'average';
    group: { type: 'organization' | 'room' | 'location'; _id: string };
    organizationId: string;
    rangeSelection: IStatRangeSelectionModel; 
}

export interface IStatState {
    fetchStats(statQuery: IStatQueryModel): Promise<ISqStoreActionResponse<Array<any>>>;
}

@Module({ dynamic: true, store, name: 'stats' })
export default class StatState extends VuexModule implements IStatState {
    private _baseQueryPayload = {
        "data": {
            // default for now, this will be diff for each graph eventually
            "stats": ["connectionCount"],
    
            // default with no filters - show stats for entire org
            // default at working room for now
            "group": {
            },
    
            // default at 3 months
            "rangeStart": null,
            "rangeEnd": null,
            "groupBy": "week",
            "aggregate": "sum",
            "organizationId": ""
        },
        "schemaVersion": 1
    };
    //#region GETTERS
    
    //#endregion

    //#region ACTIONS
    @Action
    async fetchStats(statQuery: IStatQueryModel): Promise<ISqStoreActionResponse<Array<any>>> {
        try {
            const queryPayload = Object.assign({}, this._baseQueryPayload);
            const startDate = moment(statQuery.rangeSelection.dateRange.rangeStart).startOf('day');
            const endDate = moment(statQuery.rangeSelection.dateRange.rangeEnd).endOf('day');

            queryPayload.data.group = {_id: statQuery.organizationId, type: 'organization'} as any;
            queryPayload.data.rangeStart = moment(startDate).utc().format(timeFormat) as any;
            queryPayload.data.rangeEnd = moment(endDate).utc().format(timeFormat) as any;
            queryPayload.data.groupBy = statQuery.rangeSelection.groupBy as string;
            queryPayload.data.organizationId = statQuery.organizationId as string;
          
            const authHeader =  await getAuthInstance().getAxiosHeader();
            const fetchUsersUrl = `${process.env.VUE_APP_API_HOST}/api/stats/query`;
            queryPayload.data.stats = [...statQuery.stats];
            queryPayload.data.aggregate = statQuery.aggregate;
            queryPayload.data.group = statQuery.group;
        
            const axiosResponse = await axios.post(fetchUsersUrl, queryPayload, authHeader);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            if(e.response && e.response.data) { 
                console.error(e.response.data.error)
                return {success:false, reason:e.response.data.error};
            }
            return {success:false, reason:e};
        }
    }
    //#endregion

    //#region MUTATIONS
    //#endregion
}
