
import { defineComponent } from 'vue'
import DigitalAssetUpload from '../../components/DigitalAssetUpload.vue'
import { mdiClose, mdiContentDuplicate, mdiDragHorizontalVariant } from '@mdi/js';
import Icon from '../Icon.vue'
import draggable from 'vuedraggable'

export default defineComponent({
    data() {
        return {
            signageItemLayoutData: {} as any,

            // Icons
            //-------
            mdiClose,
            mdiContentDuplicate,
            mdiDragHorizontalVariant
        }
    },

    props: {
        layoutDataEdit: {
            type: Object,
            default: null
        },
        layoutType: {
            type: String,
            default: ''
        },
         signageAssets: {
            type: Object,
            default: null
        },
    },

    components: {
        Icon,
        DigitalAssetUpload,
        draggable
    },

    computed: {
        assetUrl() {
            const tmpAssetUrl = [null, null];
            if (this.signageAssets && this.signageAssets.length) {
                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.asset1) {
                    tmpAssetUrl[0] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.asset1).thumbUrl;
                }   

                if(this.signageItemLayoutData.media && this.signageItemLayoutData.media.asset2) {
                    tmpAssetUrl[1] = this.signageAssets.find((sa: any) => sa.id == this.signageItemLayoutData.media.asset2).thumbUrl;
                }
            }
            return tmpAssetUrl;
        }
    },
    
    created () {
        this.signageItemLayoutData = JSON.parse(JSON.stringify(this.layoutDataEdit));
    },

    watch: {
        'signageItemLayoutData': {
            handler(newValue: any){
                this.$emit('updateLayoutData', {updatedLayoutData: newValue, shouldUpdatePreview: true});
            },
            deep: true
        },
    }
})
