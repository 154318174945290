import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard, unAuthGuard } from '../auth';
import PageDirtyService from '../services/PageDirtyService';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Locations from '../views/locations/Locations.vue';
import LocationDetails from '../views/locations/LocationDetails.vue';
import Receivers from '../views/receivers/Receivers.vue';
import ReceiverDetails from '../views/receivers/ReceiverDetails.vue';
import NewReceiver from '../views/receivers/NewReceiver.vue';
import OrganizationReports from '../views/organization/Reports.vue';
import OrganizationCustomization from '../views/organization/Customization.vue';
import OrganizationCompany from '../views/organization/Company.vue';
import OrganizationDeployment from '../views/organization/Deployment.vue';
import OrganizationBilling from '../views/organization/Billing.vue';

import Rooms from '../views/rooms/Rooms.vue'
import RoomsDetails from '../views/rooms/RoomsDetails.vue'
import RoomBackground from '../views/rooms/room-signs/RoomBackground.vue'
import RoomInstructions from '../views/rooms/room-signs/RoomInstructions.vue'
import RoomLink from '../views/organization/RoomLink.vue'
import Roles from '../views/roles/Roles.vue'
import RoleDetails from '../views/roles/RoleDetails.vue'
import Logout from '../views/logout/Logout.vue';
import Error from '../views/Error.vue';
import Unauthorized from '../views/Unauthorized.vue';
import Users from '../views/users/Users.vue';
import SuperUsers from '../views/admin/SuperUsers.vue';
import SuperUserDetails from '../views/admin/SuperUserDetails.vue';
import SuperOrganizations from '../views/admin/SuperOrganizations.vue';
import SuperOrganizationDetails from '../views/admin/SuperOrganizationDetails.vue';
import UserDetails from '../views/users/UserDetails.vue';
import UserSwap from '../views/users/UserSwap.vue';
import Signup from '../views/signup/Signup.vue';
import PageNotFound from '../views/PageNotFound.vue';
import ServerCheck from '../views/ServerCheck.vue';
import Alerts from '../views/alerts/Alerts.vue';
import AlertServers from '../views/alerts/AlertServers.vue';
import Signage from '../views/signage/Signage.vue';
import SignageDetails from '../views/signage/SignageDetails.vue';
import SignageList from '../views/signage/SignageList.vue';

// Signage Layouts --- 
import WayfindingLayout from '../components/signage-layouts/WayfindingLayout.vue';
import InformationalLayout from '../components/signage-layouts/InformationalLayout.vue';
import FullMenuLayout from '../components/signage-layouts/FullMenuLayout.vue';
import MenuItemLayout from '../components/signage-layouts/MenuItemLayout.vue';
import DetailedInformationalLayout from '../components/signage-layouts/DetailedInformationalLayout.vue';
import SpotlightLayout from '../components/signage-layouts/SpotlightLayout.vue';
import COVIDOccupancyNoticeLayout from '../components/signage-layouts/COVIDOccupancyNoticeLayout.vue';
import COVIDSafetyNoticeLayout from '../components/signage-layouts/COVIDSafetyNoticeLayout.vue';
import COVIDOccupancyTwoLayout from '../components/signage-layouts/COVIDOccupancyTwoLayout.vue';
import COVIDMaskLayout from '../components/signage-layouts/COVIDMaskLayout.vue';
import MediaLayout from '../components/signage-layouts/MediaLayout.vue';
import LargeHeadlineInformationalLayout from '../components/signage-layouts/LargeHeadlineInformationalLayout.vue';
import FeatureLayout from '../components/signage-layouts/FeatureLayout.vue';
import FeatureTwoLayout from '../components/signage-layouts/FeatureTwoLayout.vue';
import DirectoryLayout from '../components/signage-layouts/DirectoryLayout.vue';
import StaticWebUrl from '../components/signage-layouts/StaticWebUrlLayout.vue';
import WeatherTodayLayout from '../components/signage-layouts/WeatherTodayLayout.vue';
import WeatherThreeDayLayout from '../components/signage-layouts/WeatherThreeDayLayout.vue';
import CanvaLayout from '../components/signage-layouts/CanvaLayout.vue'; 
import TwitterLayout from '../components/signage-layouts/TwitterLayout.vue';
import MediaQuadrantsLayout from '../components/signage-layouts/MediaQuadrantsLayout.vue';
import TemplateEngine from '../views/TemplateEngine.vue';

PageDirtyService.setWindowListener();


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            description: 'Dashboard'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Dashboard
    },
    {
        path: '/signage',
        name: 'Signage',
        meta: {
            description: 'Signage'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Signage
    },
    {
        path: '/signage/:listid',
        name: 'SignageListDetails',
        meta: {
            description: 'Signage List Details'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageList
    },
    {
        path: '/signage/:listid/new',
        name: 'SignageItemSelection',
        meta: {
            description: 'Signage Item Selection'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Signage,
        props: { isSelectingLayoutType: true }
    },
    {
        path: '/signage/:listid/:itemid',
        name: 'SignageItemDetails',
        meta: {
            description: 'Signage Item Details'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageDetails,
        props: { isCreatingNewSignageItem: false }
    },
    {
        path: '/signage/:listid/new/:layouttype',
        name: 'SignageItemCreation',
        meta: {
            description: 'Signage Item Creation'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: SignageDetails,
        props: { isCreatingNewSignageItem: true }
    },
    
    //#region signage item templates
    {
        path: '/signage/template/multiitemmenu/:id/:signature',
        name: 'FullMenuLayout',
        component: FullMenuLayout,
        meta: { 
            noAuth: true,
            description: 'Full Menu Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/singleitemmenu/:id/:signature',
        name: 'MenuItemLayout',
        component: MenuItemLayout,
        meta: { 
            noAuth: true,
            description: 'Menu Item Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/informational/:id/:signature',
        name: 'InformationalLayout',
        component: InformationalLayout,
        meta: { 
            noAuth: true,
            description: 'Informational Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/detailedinformational/:id/:signature',
        name: 'DetailedInformationalLayout',
        component: DetailedInformationalLayout,
        meta: { 
            noAuth: true,
            description: 'Detailed Informational Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/spotlight/:id/:signature',
        name: 'SpotlightLayout',
        component: SpotlightLayout,
        meta: { 
            noAuth: true,
            description: 'Spotlight Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/covid-occupancy/:id/:signature',
        name: 'COVIDOccupancyNoticeLayout',
        component: COVIDOccupancyNoticeLayout,
        meta: { 
            noAuth: true,
            description: 'COVID Occupancy Notice Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/covid-safety/:id/:signature',
        name: 'COVIDSafetyNoticeLayout',
        component: COVIDSafetyNoticeLayout,
        meta: { 
            noAuth: true,
            description: 'COVID Safety Notice Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/media/:id/:signature',
        name: 'Media Layout',
        component: MediaLayout,
        meta: { 
            noAuth: true,
            description: 'Media Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/largeheadlineinformational/:id/:signature',
        name: 'LargeHeadlineInformationalLayout',
        component: LargeHeadlineInformationalLayout,
        meta: { 
            noAuth: true,
            description: 'Large Headline Informational Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/feature/:id/:signature',
        name: 'FeatureLayout',
        component: FeatureLayout,
        meta: { 
            noAuth: true,
            description: 'Feature Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/directory/:id/:signature',
        name: 'DirectoryLayout',
        component: DirectoryLayout,
        meta: { 
            noAuth: true,
            description: 'Directory Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/featuretwo/:id/:signature',
        name: 'FeatureTwoLayout',
        component: FeatureTwoLayout,
        meta: { 
            noAuth: true,
            description: 'Feature Two Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/covidoccupancytwo/:id/:signature',
        name: 'COVIDOccupancyTwoLayout',
        component: COVIDOccupancyTwoLayout,
        meta: { 
            noAuth: true,
            description: 'COVID Occupancy Two Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/covidmask/:id/:signature',
        name: 'COVIDMaskLayout',
        component: COVIDMaskLayout,
        meta: { 
            noAuth: true,
            description: 'COVIDMaskLayout'
        },
        props: true 
    },
    {
        path: '/signage/template/staticweburl/:id/:signature',
        name: 'StaticWebUrl',
        component: StaticWebUrl,
        meta: { 
            noAuth: true,
            description: 'Static Web Url'
        },
        props: true 
    },
    {
        path: '/signage/template/weathertoday/:id/:signature',
        name: 'WeatherTodayLayout',
        component: WeatherTodayLayout,
        meta: { 
            noAuth: true,
            description: 'Weather Today Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/weatherthreeday/:id/:signature',
        name: 'WeatherThreeDayLayout',
        component: WeatherThreeDayLayout,
        meta: { 
            noAuth: true,
            description: 'WeatherThreeDayLayout'
        },
        props: true 
    },
    {
        path: '/signage/template/canva/:id/:signature',
        name: 'CanvaLayout',
        component: CanvaLayout,
        meta: { 
            noAuth: true,
            description: 'Canva Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/twitter/:id/:signature',
        name: 'TwitterLayout',
        component: TwitterLayout,
        meta: { 
            noAuth: true,
            description: 'Twitter Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/wayfinding/:id/:signature',
        name: 'WayfindingLayout',
        component: WayfindingLayout,
        meta: { 
            noAuth: true,
            description: 'Wayfinding Layout'
        },
        props: true 
    },
    {
        path: '/signage/template/mediaquadrants/:id/:signature',
        name: 'MediaQuadrantsLayout',
        component: MediaQuadrantsLayout,
        meta: { 
            noAuth: true,
            descriptions: 'Media Quadrants Layout'
        },
        props: true 
    },
    {
        path: '/templateengine/:id/:signature',
        name: 'TemplateEngine',
        component: TemplateEngine,
        meta: { 
            noAuth: true,
            description: 'Signage Template Engine'
        },
        props: true 
    },
    //#endregion
    {
        path: '/alerts',
        name: 'Alerts',
        meta: {
            description: 'Alerts'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts
    },
    {
        path: '/alerts/:id',
        name: 'AlertDetails',
        meta: {
            description: 'Alert Details'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts
    },
    {
        path: '/alerts/new',
        name: 'AlertCreation',
        meta: {
            description: 'Alert Creation'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: Alerts,
        props: { creationMode: true }
    },
    {
        path: '/alertservers',
        name: 'AlertServers',
        meta: {
            description: 'Alert Servers'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers
    },
    {
        path: '/alertservers/new',
        name: 'AlertServerCreation',
        meta: {
            description: 'Alert Server Creation'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers,
        props: { creationMode: true }
    },
    {
        path: '/alertservers/:id',
        name: 'AlertServerDetails',
        meta: {
            description: 'Alert Server Details'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        component: AlertServers
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            description: 'Logout'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, null);            
        },
        component: Logout
    },
    {
        path: '/signup',
        name: 'Signup',
        meta: {
            description: 'Signup'
        },
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, unAuthGuard);       
        },
        component: Signup
    },
    {
        path: '/locations',
        name: 'Locations',
        meta: {
            description: 'Locations'
        },
        component: Locations,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/locations/new',
        name: 'LocationCreation',
        meta: {
            description: 'Location Creation'
        },
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/locations/new/:id',
        name: 'SublocationCreation',
        meta: {
            description: 'Sublocation Creation'
        },
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/locations/:id',
        name: 'LocationDetails',
        meta: {
            description: 'Location Details'
        },
        component: LocationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/receivers',
        name: 'Receivers',
        meta: {
            description: 'Receivers'
        },
        component: Receivers,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/receivers/:id',
        name: 'ReceiverDetails',
        meta: {
            description: 'Receiver Details'
        },
        component: ReceiverDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/receivers/new',
        name: 'ReceiverCreation',
        meta: {
            description: 'Receiver Creation'
        },
        component: NewReceiver,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true,
    },
    {
        path: '/activate',
        name: 'NewReceiverActivation',
        meta: {
            description: 'New Receiver Activation'
        },
        component: NewReceiver,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true,
    },
    {
        path: '/rooms',
        name: 'Rooms',
        meta: {
            description: 'Rooms'
        },
        component: Rooms,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);
        },
        props: true
    },
    {
        path: '/rooms/:id',
        name: 'RoomsDetails',
        meta: {
            description: 'Rooms Details'
        },
        component: RoomsDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/rooms/new',
        name: 'RoomCreation',
        meta: {
            description: 'Room Creation'
        },
        component: RoomsDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true}
    },
    {
        path: '/rooms/background/:id',
        name: 'RoomBackground',
        meta: {
            description: 'Room Background'
        },
        component: RoomBackground,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/rooms/instructions/:id',
        name: 'RoomInstructions',
        meta: {
            description: 'Room Instructions'
        },
        component: RoomInstructions,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/reports',
        name: 'OrganizationReports',
        meta: {
            description: 'Organization Reports'
        },
        component: OrganizationReports,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/company',
        name: 'OrganizationCompany',
        meta: {
            description: 'Organization Company'
        },
        component: OrganizationCompany,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/customization',
        name: 'OrganizationCustomization',
        meta: {
            description: 'Organization Customization'
        },
        component: OrganizationCustomization,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/deployment',
        name: 'OrganizationDeployment',
        meta: {
            description: 'Organization Deployment'
        },
        component: OrganizationDeployment,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/organization/billing',
        name: 'OrganizationBilling',
        meta: {
            description: 'Organization Billing'
        },
        component: OrganizationBilling,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
    },
    {
        path: '/organization/deployment/quicklink',
        name: 'RoomLink',
        meta: {
            description: 'Room Link'
        },
        component: RoomLink,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/roles',
        name: 'Roles',
        meta: {
            description: 'Roles'
        },
        component: Roles,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/roles/new',
        name: 'RoleCreation',
        meta: {
            description: 'Role Creation'
        },
        component: RoleDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: { creationMode:true }
    },
    {
        path: '/roles/:id',
        name: 'RoleDetails',
        meta: {
            description: 'Role Details'
        },
        component: RoleDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/error',
        name: 'Error',
        meta: { 
            noAuth: true,
            description: 'Error'
        },
        component: Error,
        props: true
    },
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        meta: {
            description: 'Unauthorized'
        },
        component: Unauthorized,
        props: true
    },
    {
        path: '/users/',
        name: 'Users',
        meta: {
            description: 'Users'
        },
        component: Users,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/users/',
        name: 'SuperUsers',
        meta: {
            description: 'Super Users'
        },
        component: SuperUsers,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/users/:id',
        name: 'SuperUserDetails',
        meta: {
            description: 'Super User Details'
        },
        component: SuperUserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/organizations/',
        name: 'SuperOrganizations',
        meta: {
            description: 'Super Organizations'
        },
        component: SuperOrganizations,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/admin/organizations/:id',
        name: 'SuperOrganizationDetails',
        meta: {
            description: 'Super Organization Details'
        },
        component: SuperOrganizationDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/users/:id',
        name: 'UserDetails',
        meta: {
            description: 'User Details'
        },
        component: UserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: true
    },
    {
        path: '/users/new',
        name: 'UserCreation',
        meta: {
            description: 'User Creation'
        },
        component: UserDetails,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        },
        props: {creationMode:true }
    },
    {
        path: '/users/swap',
        name: 'UserSwap',
        meta: {
            description: 'User Swap'
        },
        component: UserSwap,
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, authGuard);            
        }
    },
    {
        path: '/server_check',
        name: 'ServerCheck',
        meta: {
            description: 'Server Check'
        },
        component: ServerCheck
    },
    {
        // path: '*',
        path: "/:catchAll(.*)",
        beforeEnter: (to, from, next) => {
            PageDirtyService.pageGuard(to, from, next, null);            
        },
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


router.beforeEach((to) => {
    const pageTitle = to.meta.description ? `Ditto Account Portal | ${to.meta.description}` : 'Ditto Account Portal | Smart and simple screen sharing for any space';
    (document as any).title = pageTitle;

    // Hubspot hsq definition
    (window as any).hsq = {};
    (window as any).hsq._hsq = (window as any)._hsq = (window as any)._hsq || [];

    let pathToPush = '';
    const fullPath = to.fullPath;

    // Check if we're at the catch all error page
    if(to.params.catchAll) {
        pathToPush = '/catchAll';
    }

    // Check if we are at a details page
    // For ex: rooms/623e2440-05b2-11ec-98c0-4562d2391f69
    else if(to.params.id) {
        const splitFullPath = fullPath.split("/");
        pathToPush = '/' + splitFullPath[1] + '/id';
        
    // At a regular URL whos path we can push
    // For ex: /rooms, /organization/customization
    } else {
        pathToPush = fullPath;
    }

    // We have a variable defined on the window in public > index.html
    (window as any)._hsq.push(['setPath', pathToPush]);
    (window as any)._hsq.push(['trackPageView']);

    return true;
})

export default router
