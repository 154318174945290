
import { getModule } from 'vuex-module-decorators';
import { IOrganization } from '../../store/interfaces/IOrganization';
import OrganizationState, { IOrganizationState } from '../../store/modules/organizations';
import { defineComponent } from 'vue';
import PageDirtyService from '../../services/PageDirtyService';
import UserState, { IUserState } from '../../store/modules/users';
import { IUser } from '../../store/interfaces/IUser';
import RoomState, { IRoomState } from '../../store/modules/rooms';
import { IRoom, IRoomListDisplay, EnableRoomSettings, EnableRoomLocationSettings } from '../../store/interfaces/IRoom';
import { ILocationListDisplay } from '../../store/interfaces/ILocation';
import shortid from 'shortid';
import OrgUsageStatBarGraphs from '../../components/OrgUsageStatBarGraphs.vue';
import { IRoomModelSearch } from '../../store/interfaces/IModelSearch';
import { mdiCheckBold, mdiInformationOutline, mdiAlertCircleOutline, mdiHelp } from '@mdi/js';
import Icon from "../../components/Icon.vue";
import TierState, { ITierState } from '../../store/modules/tier';
import { ITier, ICheckTierChangePayload } from '../../store/interfaces/ITier';
import SubscriptionState, { ICalculateSubscriptionAdjustmentInput, IUpdateSubscriptionInput, ISubscriptionState } from '../../store/modules/subscriptions';
import { IPairing } from '../../store/interfaces/IPairing';
import moment from 'moment';
import RoleState, { IRoleState } from '../../store/modules/role';
import RoomsImport from '../../components/admin/roomsImport.vue';
import ReceiverState, { IReceiverState } from '../../store/modules/receivers';
import { IReceiverModelSearch } from '../../store/interfaces/IModelSearch';
import { IReceiver, IReceiverListDisplay } from '../../store/interfaces/IReceiver';
import OnboardingState, {IOnboardStatusState} from '../../store/modules/onboarding';

let organizationState = {} as IOrganizationState;
let userState = {} as IUserState;
let roomState = {} as IRoomState;
let tierState = {} as ITierState;
let subscriptionState = {} as ISubscriptionState;
let roleState = {} as IRoleState;
let receiverState = {} as IReceiverState;
let onboardingState = {} as IOnboardStatusState;


interface IAdminRoomDetailsDisplay extends IRoom {
    enableHigherResolutionBoolean: boolean;
    showSignageDateTimeBoolean: boolean;
    signageDateTimeLocationString: string;
    receivers: IReceiverListDisplay[];
    hasSSPReceiver: boolean;
    isOnboarding: boolean;
    onboardingUser?: any
}

interface IAdminRoomDisplay extends IRoomListDisplay {
    roomDetails: IAdminRoomDetailsDisplay;  
    expandRoomLoading: boolean;
}

interface IAdminReceiverDetailsDisplay extends IReceiver {
    pairing?: IPairing;
    isOnboarding: boolean;
    onboardingUser?: any;
}

interface IAdminReceiverDisplay extends IReceiverListDisplay {
    receiverDetails: IAdminReceiverDetailsDisplay;  
    expandReceiverLoading: boolean;
}
 
export default defineComponent ({
    name: 'OrganizationDetails',
    data() {
        return {
            activeTab: "overview" as string,
            organization: {} as IOrganization,
            refund: {
                refundAmount: null as number | null
            } as any,
            users: [] as IUser[],
            locations: [] as ILocationListDisplay[],
            roomSearch: '' as string,
            roomSortField: '' as string,
            roomSortOrder: '' as string,
            searchDebounce: null as any,
            roomSearchResults: [] as IAdminRoomDisplay[],
            roomCurrentPage: 1 as number,
            receiverSearch: '' as string,
            receiverSearchResults: [] as IAdminReceiverDisplay[],
            receiverCurrentPage: 1 as number,
            receiverSortField: '' as string,
            receiverSortOrder: '' as string,
            viewLoading: true,
            usersLoading: true,
            roomsLoading: false,
            receiversLoading: false,
            saving: false,
            roomCount: 0 as number,
            receiverCount: 0 as number,
            roomsCreatedCount: 0,
            locationsCreatedCount: 0,
            subLocationsCreatedCount: 0,
            ogBillingType: '',
            mdiCheckBold,
            mdiInformationOutline,
            mdiAlertCircleOutline,
            mdiHelp,
            allTiers: [] as ITier[],
            orgTier: {} as ITier,
            currentPlanId: '' as string,
            currentBillingFrequency: '' as string,
            showChangeMessage: false as boolean,
            currentRefundAmount: '' as string,
            currentSubscription: {} as any,
            featureChangeList: [] as string[],
            restrictionsChangeList: [] as string[],
            newSubscriptionTotal: '' as string,
            latestInvoiceTotal: 0 as number,
            currentSubscriptionLimit: 0 as number,
            isPlanChangeConfirmationVisibile: false,
            isRefundModalVisibile: false,
            planDisabledText: 'Ditto plan does not include this feature.',
            rules:{
                'name':[{ required: true, message: 'Organization name is required.', trigger: 'change'}],
                'tierId':[{ required: true, validator: this.checkTierChange, trigger: 'change' }],
                'billing.billing_type':[{ validator: this.validateBillingType, trigger: 'change' }],
                'billing.subscription_limit':[{ validator: this.validateSubscriptionLimit, trigger: 'change' }],
                'billing.customer_id':[{ validator: this.validateStripeCustomerId, trigger: 'change' }],
                'billing.billingFrequency':[{required: true, validator: this.validateBillingFrequencyChange, trigger: 'change' }],
                'restrictions.restrictionUsers':[{ validator: this.validateUserOverride, trigger: 'change' }],
                'restrictions.restrictionReceivers':[{ validator: this.validateReceiverOverride, trigger: 'change' }],
                'restrictions.restrictionSignageItems':[{ validator: this.validateSignageItemOverride, trigger: 'change' }],
                'restrictions.restrictionStorageGBPerRec':[{ validator: this.validateStorageOverride, trigger: 'change' }]
            },
            refundRules: {
                'refundAmount':[{ validator: this.validateRefund, trigger: 'change'}]
            }
        }
    },

    methods: {
        async validateForm (): Promise<boolean> {
            if((this.organization.billing.billing_type === 'Trial' && this.organization.tierId !== 'trial') || (this.organization.billing.billing_type !== 'Trial' && this.organization.tierId === 'trial')) {
                this.organization.billing.billing_type = 'Trial';
                this.organization.tierId = 'trial';
            }
            return new Promise((resolve) => {
                (this as any).$refs['form'].validate((valid: boolean, obj: any) => {
                    if(!valid) {
                        let errorHTML = '';
                        for(const key in obj) {
                            for(const err of obj[key]) {
                                errorHTML = `${errorHTML}${err.message}<br>`;
                            }
                        }
                        console.error(errorHTML);
                        (this as any).$message({
                            dangerouslyUseHTMLString: true,
                            message: errorHTML,
                            type: 'error'
                        });
                    }
                    resolve(valid);
                });
            });
        },

        async validatRefundForm (): Promise<boolean> {
            return new Promise((resolve) => {
                (this as any).$refs['refundForm'].validate((valid: boolean) => {
                    resolve(valid);
                });
            });
        },

        async validateStripeCustomerId(rule: any, value: any, callback: any)  {
            if(!this.organization.billing || this.organization.billing.billing_type != 'Stripe') {
                callback();
                return;
            }
            
            if(!value || value.length < 1) {
                callback('Stripe Customer Id required when billing type is Stripe');
                return;
            }

            callback();

        },

        async validateSubscriptionLimit(rule: any, value: any, callback: any)  {
            if(this.selectedTier.id == 'trial' && value == 0) {
                callback();
                return;
            }

            if(!value) {
                callback('Subscription limit is required.');
                return;
            }

            if(!Number.isInteger(value)){
                callback('Subscription limit must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.receiverCount) {
                callback(`Subscription limit must be above or equal to the current active receiver count of ${tierState.orgTierUsage.receiverCount}.`);
                return;
            }

           if(this.organization.restrictions && this.organization.restrictions.restrictionReceivers && value > this.organization.restrictions.restrictionReceivers) {
                callback('Subscription limit should be less than the plan override limit for receivers.');
                return;
            }

            if((!this.organization.restrictions || !this.organization.restrictions.restrictionReceivers) && this.selectedTier.restrictionReceivers != 0 && value > this.selectedTier.restrictionReceivers) {
                callback('Subscription limit should be less than the plan limit for receivers.');
                return;
            }

            if(this.selectedTier.restrictionReceivers == -1 && value != this.selectedTier.restrictionReceivers) {
                callback('Subscription limit cannot be changed due to plan limit for receivers.');
                return;
            }

            this.newSubscriptionTotal = '';
            if(this.organization.billing.billing_type === 'Stripe' && this.currentSubscriptionLimit != value) {
                this.viewLoading = true;
                await this.previewChange();
                this.viewLoading = false;
            }
            
            callback();
        },

        async previewChange() {
            const payload = {
                customerId: this.organization.billing.customer_id,
                quantity: this.organization.billing.subscription_limit,
                tierId: this.selectedTier.stripeProductId,
                billingFrequency: this.organization.billing.billingFrequency
            } as ICalculateSubscriptionAdjustmentInput;

            const amount = await subscriptionState.calculateSubscriptionAdjustment(payload);
           
            if(amount.data) {
                this.newSubscriptionTotal = amount.data;
            }
        },

        async validateBillingType(rule: any, value: any, callback: any) {
            if(!value) {
                callback('Billing Type is required.');
                return;
            } 

            if(value === 'Trial') {
                this.organization.tierId = 'trial';
            }
           
            if(value !== 'Trial' && this.organization.tierId === 'trial') {
                callback('Billing Type must be Trial when Ditto Plan is Trial.');
                return;
            }

            if(value !== 'Trial' && this.organization.tierId !== 'trial') {
                (this as any).$refs['form'].clearValidate();
            }

            callback();
            return;
        },

        async checkTierChange(rule: any, value: any, callback: any) {
            if(!value) {
                callback('Ditto Plan is required.');
                return;
            } 

            if(value === 'trial') {
                this.organization.billing.billing_type = 'Trial';
            }

            if(this.organization.billing.billing_type === 'Trial' && value !== 'trial') {
                callback('Ditto Plan must be Trial when Billing Type is Trial.');
                return;
            }

            if(this.organization.billing.billing_type !== 'Trial' && value !== 'trial') {
                (this as any).$refs['form'].clearValidate();
            }

            this.newSubscriptionTotal = '';
            this.showChangeMessage = false;
            this.featureChangeList = [];
            this.restrictionsChangeList = [];
            if(value == this.currentPlanId) { 
                this.viewLoading = false; 
                callback();
                return; 
            }

            this.viewLoading = true;
            const currentPlan = this.allTiers.find((x: any) => x.id == this.currentPlanId)

            let payload = {
                organization: this.organization,
                oldTier: currentPlan as ITier,
                newTier: this.selectedTier,
                newSubscriptionLimit: this.organization.billing.subscription_limit
            } as ICheckTierChangePayload
            
            const results = await tierState.checkTierChange(payload);
            
            this.showChangeMessage = results.data.allowChange;
            this.featureChangeList = results.data.featureUsedIds;
            this.restrictionsChangeList = results.data.overRestrictionsIds;
            if(!results.data.allowChange) {
                this.viewLoading = false;   
                callback('Unable to change Ditto plan. Reasons highlighted below.');
                return;
            } 

            if(this.organization.billing.billing_type === 'Stripe' && this.selectedTier.id != 'trial' && this.selectedTier.id != this.currentPlanId) {
                await this.previewChange();
            }
        
            this.viewLoading = false;
            callback();    
            return;      
        },

        async validateBillingFrequencyChange(rule: any, value: any, callback: any) {
            if(this.organization.billing.billing_type === 'Stripe' && this.organization.tierId && !value) {
                callback('Billing frequency is required.');
                return;
            }

            this.newSubscriptionTotal = '';
            this.viewLoading = true;
           
            if(this.organization.billing.billing_type === 'Stripe' && this.organization.billing.billingFrequency != this.currentBillingFrequency) {
                await this.previewChange();
            }

            this.viewLoading = false;
            callback();    
        },

        validateUserOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionUsers!=0 && this.selectedTier.restrictionUsers < tierState.orgTierUsage.userCount) {
                    return callback(`Current user count of ${tierState.orgTierUsage.userCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }
            
            if(value < tierState.orgTierUsage.userCount) {
                return callback(`Override must be below or equal to the current user count of ${tierState.orgTierUsage.userCount}.`);
            }

            callback();
        },

        validateReceiverOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionReceivers != 0 && this.selectedTier.restrictionReceivers < tierState.orgTierUsage.receiverCount) {
                    return callback(`Current receiver count of ${tierState.orgTierUsage.receiverCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.receiverCount) {
                return callback(`Override must be above or equal to the current active receiver count of ${tierState.orgTierUsage.receiverCount}.`);
            }
            
            callback();
        },

        validateSignageItemOverride(rule: any, value: any, callback: any) {
            if(!value) { 
                if(this.selectedTier.restrictionSignageItems!= 0 && this.selectedTier.restrictionSignageItems < tierState.orgTierUsage.signageItemCount) {
                    return callback(`Current signage item count of ${tierState.orgTierUsage.signageItemCount} is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(!Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }

            if(value < tierState.orgTierUsage.signageItemCount) {
                return callback(`Override must be above or equal to the current signage item count of ${tierState.orgTierUsage.signageItemCount}.`);
            }
            
            callback();
        },

        validateStorageOverride(rule: any, value: any, callback: any) {
            if(value && !Number.isInteger(value)){
                callback('Override must be a number value.');
                return;
            }
            
            const usage = tierState.orgTierUsage.storageUsedGB ? Number(tierState.orgTierUsage.storageUsedGB) : 0;
            
            if(!value) { 
                const totalAllowedStorage = (this.orgTier.restrictionStorageGBPerRec) * this.organization.billing.subscription_limit;
                if(totalAllowedStorage!= 0 && totalAllowedStorage < usage) {
                    return callback(`'Current storage usage of ${ usage.toFixed(2)} GB is greater than the ${this.selectedTier.name} Ditto Plan limit. Add an override or select a different Ditto Plan.`);
                }
                return callback(); 
            }

            if(value * this.organization.billing.subscription_limit < usage) {
                return callback(`Override must be above or equal to the current storage of ${usage.toFixed(2)} GB.`);
            }
            
            callback();
        },

        validateRefund(rule: any, value: any, callback: any) {
            if(!value) { return callback(); }

            if(!Number(value)){
                callback('Refund must be a number value.');
                return;
            }

            if(value > this.currentRefundAmount) {
                return callback('Refund cannot be larger than available refund.');
            }

            if(value > this.latestInvoiceTotal) {
                return callback('Refund cannot be larger than charge from latest invoice.');
            }

            if (!value.includes('.') || value.indexOf(".") >= 0) {
                const decimalPos = value.indexOf(".");
                let rightSide = value.substring(decimalPos+1);
     
                if(rightSide.length != 2) {
                    return callback('Refund amount must have 2 decimal places.');
                }
            }
            else {
                this.refund.refundAmount = Number(this.refund.refundAmount.toFixed(2));
            }

            callback();
        },

        async onSubmitClick() {
            if(this.ogBillingType != this.organization.billing.billing_type || this.currentPlanId != this.organization.tierId) {
                if((this.organization.billing.billing_type != 'Trial' && this.organization.tierId == 'trial') || (this.organization.billing.billing_type == 'Trial' && this.organization.tierId != 'trial')) {
                    await (this as any).$confirm(`Both Billing Type and Ditto Plan will be set to Trial.`, 'Warning', {
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    });
                }
            }

            this.viewLoading = true;

            if(!await this.validateForm()) { return; }

            if(this.totalChangeText) {
                this.isPlanChangeConfirmationVisibile = true;
                return;
            }
            else {
                await this.onSubmit();
            }

            this.viewLoading = false;
        },

        async onSubmit() {
            //if the original plan was stripe but it's being changed to PO or Trial, cancel Stripe subscription
            if(this.ogBillingType == 'Stripe' && this.organization.billing.billing_type != 'Stripe') {
                await (this as any).$confirm(`Changing billing type to ${this.organization.billing.billing_type} will cancel Stripe subscription at the end of the billing cycle?`, 'Warning', {
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                });

                if(this.currentSubscription) {
                    const cancelSubscriptionResponse = await subscriptionState.cancelSubscription(this.currentSubscription.id);
                
                    if(!cancelSubscriptionResponse.success) {
                        (this as any).$message.error("Canceling Subscription Failed", cancelSubscriptionResponse.reason);
                        this.saving = false;
                        this.viewLoading = false;
                        return;
                    }
                }
            }

            this.saving = true;
            this.viewLoading = true;

            //update stripe subscription if there is a new tier or billing frequency changes
             if(this.organization && this.organization.billing && this.organization.billing.billing_type === 'Stripe' && 
             (this.selectedTier.id != this.currentPlanId || 
             this.organization.billing.billingFrequency != this.currentBillingFrequency || 
             this.organization.billing.subscription_limit != this.currentSubscriptionLimit)) {
                
                const payload = {
                    customerId: this.organization.billing.customer_id,
                    tier: this.selectedTier,
                    quantity: this.organization.billing.subscription_limit,
                    organization: this.organization,
                    calculatedTotal: this.newSubscriptionTotal,
                    billingFrequency: this.organization.billing.billingFrequency
                } as IUpdateSubscriptionInput;
               
                const subscriptionResponse = await subscriptionState.updateSubscription(payload);

                if(!subscriptionResponse.success) {
                    (this as any).$message.error("Subscription Update Failed", subscriptionResponse.reason);
                    this.saving = false;
                    this.viewLoading = false;
                    return;
                }

                if(subscriptionResponse.data) {
                    const updatedSubscription = subscriptionResponse.data.find((x: any) => x.id == this.currentSubscription.id);
                    if(updatedSubscription && updatedSubscription.stripeRefundAmount) {
                        this.organization.billing.stripeRefundAmount = updatedSubscription.stripeRefundAmount;
                    }
                }
            }

            // Remove overrides is not a PO
            if(!this.isPO && this.organization.restrictions) {
                this.organization.restrictions = {
                    restrictionReceivers: null as any,
                    restrictionUsers: null as any,
                    restrictionSignageItems: null as any,
                    restrictionStorageGBPerRec: null as any
                }
            }

            // Make sure is_active is true when changing to PO
            if(this.ogBillingType != 'PO' && this.isPO){
                this.organization.billing.is_active = true;
            }
            
            const storeResponse = await organizationState.updateOrganization(this.organization);
            if(!storeResponse.success) {
                (this as any).$message.error("Update Failed", storeResponse.reason);
                this.saving = false;
                this.viewLoading = false;
                return;
            }

            const loadOrgsResponse = await organizationState.fetchOrganizations(this.$route.params.id as string);
            if(!loadOrgsResponse.success) {
                this.viewLoading = false;
                this.organization = {} as IOrganization;
                return;
            }

            this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
            this.ogBillingType = this.organization.billing.billing_type;
           
            await tierState.fetchTier(this.organization.id);
            this.orgTier = tierState.orgTier;

            this.currentPlanId = this.organization.tierId;
            this.currentBillingFrequency = this.organization.billing.billingFrequency;
            this.currentSubscriptionLimit = this.organization.billing.subscription_limit;
            this.currentRefundAmount = this.organization.billing.stripeRefundAmount && Number(this.organization.billing.stripeRefundAmount.replace('$', '')) ? Number(this.organization.billing.stripeRefundAmount.replace('$', '')).toFixed(2) : Number(0.00).toFixed(2);
            
            (this as any).$message.success('Organization updated.');

            this.saving = false;
            this.viewLoading = false;
            this.isPlanChangeConfirmationVisibile = false;
            this.newSubscriptionTotal = '';
        },

        async onCancel() {
            await (this as any).$confirm('Are you sure you wish to undo org changes?', 'Warning', {
                confirmButtonText: 'Undo Changes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            });
            this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
            this.ogBillingType = this.organization.billing.billing_type
        },

        createPlanOverrides() {
            this.organization.restrictions = {
                restrictionReceivers: null as any,
                restrictionUsers: null as any,
                restrictionSignageItems: null as any,
                restrictionStorageGBPerRec: null as any
            }
        },

        createDeploymentProperties() {
            this.organization.deployment = {
                autoCreateRooms: false,
                autoLinkRooms: false,
                exposeAutoDeploymentOptions: false,
                deploymentCode: shortid.generate().toUpperCase()
            } as any;
        },

        handleRoomCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.roomCurrentPage = pg;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        handleRoomSearchChange() {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.roomCurrentPage = 1;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        handleRoomSortChange(sort: any) {
            this.roomSortField = sort.prop;
            this.roomSortOrder = sort.order;
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.roomCurrentPage = 1;
                this.doRoomSearch();
            this.roomsLoading = true;
            }, 1000);            
        },

        doRoomSearch() {
            const roomSearchObj: IRoomModelSearch = {
                pageIndex:this.roomCurrentPage, 
                pageSize:10, 
                organizationId:this.organization.id
            };

            if(this.roomSearch != '') {
                const formatedSearch = this.roomSearch.replace('#', '');
                roomSearchObj.name = formatedSearch;
            }

            if(this.roomSortField && this.roomSortOrder) {
                roomSearchObj.sortField = this.roomSortField;

                let tempRoomSortOrder: 'asc' | 'desc' = 'asc';
                if(this.roomSortOrder == 'descending') tempRoomSortOrder = 'desc';

                roomSearchObj.sortOrder = tempRoomSortOrder;
            }

            roomState.fetchRoomDisplayList(roomSearchObj).then(storeRes => {
                this.roomSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data as IAdminRoomDisplay[] : [] as IAdminRoomDisplay[];
                this.roomCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
                this.roomsLoading = false;
            });
        },

        isPlatform(platform: string, receiverPlatform: string): boolean {
            if(!platform || !receiverPlatform) {
                return false;
            }

            return platform.toLowerCase() === receiverPlatform.toLowerCase();
        },

        isSSP(deviceType: string): boolean {
            if(!deviceType) return false;
            return deviceType.toLowerCase() === 'ssp'
        },

        isVersionLessThan(receiver: any, major: number, minor: number, patch: number) {
            if(!receiver == null || !receiver.lastKnownVersionMajor == null || !receiver.lastKnownVersionMinor == null || !receiver.lastKnownVersionPatch == null) return true;

            if(receiver.lastKnownVersionMajor > major) return false;
            if(receiver.lastKnownVersionMajor == major && receiver.lastKnownVersionMinor > minor) return false;
            if(receiver.lastKnownVersionMajor == major && receiver.lastKnownVersionMinor == minor && receiver.lastKnownVersionPatch >= patch) return false;

            return true;
        },

        getDeviceTypeDisplay(deviceType: string): string {
            if(deviceType && deviceType.toLocaleLowerCase() === 'ssp') {
                return 'Ditto Receiver';
            }

            return deviceType ? deviceType : '';
        },

        getReceiverPlatformDisplay(platform: string) {
            switch (platform) {
                case 'atvlegacy':
                    return 'ATV 2/3';

                case 'chromecast':
                    return 'Chromecast';

                case 'other': 
                    return 'Other'
                
                case 'windows':
                    return 'Windows';

                case 'tvos':
                    return 'tvOS';

                default:
                    return '';
            }

        },

        getSecurityDisplay(security: string) {
            switch (security) {
                case 'none':
                    return 'None';
                case 'onScreen':
                    return 'Onscreen Code';
                case 'onScreenOneTime':
                    return 'One-Time Onscreen Code';
                default:
                    return '';
            }
        },

        getPositionDisplay(position: string) {
            switch (position) {
                case 'center':
                    return 'Center';
                case 'topLeft':
                    return 'Top Left';
                case 'bottomLeft':
                    return '"Bottom Left';
                case 'topRight':
                    return 'Top Right';
                case 'bottomRight':
                    return 'Bottom Right'; 
                default:
                    return '';
            }
        },

        getLoggingDisplay(logging: string) {
            switch (logging) {
                case 'verbose':
                    return 'Verbose';
                case 'debug':
                    return 'Debug';
                case 'info':
                    return 'Normal';
                default:
                    return '';
            }
        },

        receiverStatus(status: any): string {
            let recStatus = '-';
            switch(status) {
                case 1:
                    recStatus = 'LEGACY';
                    break;
                case 2: 
                    recStatus = 'OFFLINE';
                    break;
                case 3:
                    recStatus = 'ONLINE';
                    break;
            }

            return recStatus;
        },

        handleReceiverCurrentPageChange(pg: number) {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.receiverCurrentPage = pg;
                this.doReceiverSearch();
            this.receiversLoading = true;
            }, 1000);            
        },

        handleReceiverSearchChange() {
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.receiverCurrentPage = 1;
                this.doReceiverSearch();
            this.receiversLoading = true;
            }, 1000);            
        },

        handleReceiverSortChange(sort: any) {
            this.receiverSortField = sort.prop;
            this.receiverSortOrder = sort.order;
            if(this.searchDebounce) clearTimeout(this.searchDebounce);
            this.searchDebounce = setTimeout(()=> {
                this.receiverCurrentPage = 1;
                this.doReceiverSearch();
            this.receiversLoading = true;
            }, 1000);            
        },

        doReceiverSearch() {
            const receiverSearchObj: IReceiverModelSearch = {
                pageIndex:this.receiverCurrentPage, 
                pageSize:10, 
                organizationId:this.organization.id
            };

            if(this.receiverSearch != '') {
                const formatedSearch = this.receiverSearch.replace('#', '');
                receiverSearchObj.name = formatedSearch;
            }

            if(this.receiverSortField && this.receiverSortOrder) {
                receiverSearchObj.sortField = this.receiverSortField;

                let tempReceiverSortOrder: 'asc' | 'desc' = 'asc';
                if(this.receiverSortOrder == 'descending') tempReceiverSortOrder = 'desc';

                receiverSearchObj.sortOrder = tempReceiverSortOrder;
            }

            receiverState.fetchReceiverDisplayList(receiverSearchObj).then(storeRes => {
                this.receiverSearchResults = storeRes.data && storeRes.data.data ? storeRes.data.data as IAdminReceiverDisplay[] : [] as IAdminReceiverDisplay[];
                this.receiverCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
                this.receiversLoading = false;
            });

            this.receiversLoading = false;
        },

        async loadReceivers() {
            this.receiversLoading = true;

            const receiverResponse = await receiverState.fetchReceiverDisplayList({pageIndex:1, pageSize:10, organizationId:this.organization.id});
            this.receiverSearchResults = receiverResponse.success && receiverResponse.data ? receiverResponse.data.data as IAdminReceiverDisplay[] : [] as IAdminReceiverDisplay[];
            this.receiverCount = receiverResponse.success && receiverResponse.data && receiverResponse.data.itemsCount ? receiverResponse.data.itemsCount : 0;

            this.receiversLoading = false;
        },

        async loadReceiverDetails(row: any, expandedRows: any) {
            if(expandedRows.length > 0) {
                const expandedRowIndex = this.receiverSearchResults.findIndex(x => x.id == row.id);
                this.receiverSearchResults[expandedRowIndex].expandReceiverLoading = true;
                if(!this.receiverSearchResults[expandedRowIndex].receiverDetails) {
                    const receiverDetails = (await receiverState.fetchReceiver({id: row.id as string, reload: true})).data as IAdminReceiverDetailsDisplay;
                    this.receiverSearchResults[expandedRowIndex].receiverDetails = receiverDetails ? receiverDetails : {} as IAdminReceiverDetailsDisplay;

                    const pairingResponse = await receiverState.getPairingByReceiverId(row.id)
                    if(pairingResponse.success && pairingResponse.data) {
                        this.receiverSearchResults[expandedRowIndex].receiverDetails.pairing = pairingResponse.data;
                    }

                    const receiverOnboardingDetails = await onboardingState.fetchActiveOnboardByReceiverId(row.id);
                    
                    this.receiverSearchResults[expandedRowIndex].receiverDetails.isOnboarding = receiverOnboardingDetails.success && receiverOnboardingDetails.data && receiverOnboardingDetails.data.length > 0;
                    if(this.receiverSearchResults[expandedRowIndex].receiverDetails.isOnboarding) {
                        const userDetails = this.users.find((x: any) => x.id == receiverOnboardingDetails.data[0].user.id);
                        if(userDetails) {
                            this.receiverSearchResults[expandedRowIndex].receiverDetails.onboardingUser = {
                                id: userDetails.id,
                                name: userDetails.name
                            }
                        }
                    }
                }
                this.receiverSearchResults[expandedRowIndex].expandReceiverLoading = false;
            }
        },

        formatConnectionPosition(position: string) {
            if(!position) { return undefined; }

            if(position.toLowerCase() == 'top left') {
                return 'topLeft';
            }

            if(position.toLowerCase() == 'top right') {
                return 'topRight';
            }

            if(position.toLowerCase() == 'bottom left') {
                return 'bottomLeft';
            }

            if(position.toLowerCase() == 'bottom right') {
                return 'bottomRight';
            }

            if(position.toLowerCase() == 'topleft') {
                return 'Top Left';
            }

            if(position.toLowerCase() == 'topright') {
                return 'Top Right';
            }

            if(position.toLowerCase() == 'bottomleft') {
                return 'Bottom Left';
            }

            if(position.toLowerCase() == 'bottomright') {
                return 'Bottom Right';
            }

            return undefined;
        },

        formatModeratorType(type: string) {
            if(!type || type.toLowerCase() == 'onscreenonetime') {
                return 'One-time onscreen code';
            }

            if(type.toLowerCase() == 'onscreen') {
                return 'Onscreen code';
            }

            if(type.toLowerCase() == 'password') {
                return 'Custom password';
            }

            return undefined;
        },

        async loadRooms() {
            this.roomsLoading = true;

            await roomState.fetchRoomDisplayList({pageIndex:1, pageSize:10, organizationId:this.organization.id}).then((storeRes: any) => {
                this.roomSearchResults = storeRes.success && storeRes.data.data ? storeRes.data.data as IAdminRoomDisplay[] : [] as IAdminRoomDisplay[];
                this.roomCount = storeRes.data && storeRes.data.itemsCount ? storeRes.data.itemsCount : 0;
            })

            this.roomsLoading = false;
        },

        async loadRoomDetails(row: any, expandedRows: any) {
            if(expandedRows.length > 0) {
                const expandedRowIndex = this.roomSearchResults.findIndex(x => x.id == row.id);
                this.roomSearchResults[expandedRowIndex].expandRoomLoading = true;
                if(!this.roomSearchResults[expandedRowIndex].roomDetails) {
                    const roomDetails = (await roomState.fetchRoom({id: row.id as string, reload: true})).data as IAdminRoomDetailsDisplay;
                    this.roomSearchResults[expandedRowIndex].roomDetails = roomDetails ? roomDetails : {} as IAdminRoomDetailsDisplay;
                    this.roomSearchResults[expandedRowIndex].roomDetails.enableHigherResolutionBoolean = this.enableHigherResolutionSetting(this.roomSearchResults[expandedRowIndex].roomDetails.enableHigherResolution);
                    this.roomSearchResults[expandedRowIndex].roomDetails.showSignageDateTimeBoolean = this.showSignageDateTimeSetting(this.roomSearchResults[expandedRowIndex].roomDetails.showSignageDateTime);
                    this.roomSearchResults[expandedRowIndex].roomDetails.signageDateTimeLocationString = this.signageDateTimeLocationSetting(this.roomSearchResults[expandedRowIndex].roomDetails.signageDateTimeLocation);

                    if(this.roomSearchResults[expandedRowIndex].roomDetails.devices && this.roomSearchResults[expandedRowIndex].roomDetails.devices.length > 0) {
                        const deviceIds = this.roomSearchResults[expandedRowIndex].roomDetails.devices.map(x=>x.id);
                        const receiverLookup: IReceiverModelSearch = {
                            pageIndex: 1,
                            pageSize: (deviceIds.length + 1),
                            ids: deviceIds,
                            organizationId: this.organization.id
                        };

                        const lookupResponse = await receiverState.fetchReceiverDisplayList(receiverLookup);

                        if(lookupResponse.data && lookupResponse.data.data) {
                            this.roomSearchResults[expandedRowIndex].roomDetails.receivers = lookupResponse.data.data;
                            this.roomSearchResults[expandedRowIndex].roomDetails.hasSSPReceiver = this.roomSearchResults[expandedRowIndex].roomDetails.receivers.some(x => x.deviceType.toUpperCase() === 'SSP');
                            this.roomSearchResults[expandedRowIndex].roomDetails.receivers = this.roomSearchResults[expandedRowIndex].roomDetails.receivers.map((x: any) => {
                                return {...x, 
                                    deviceType: x.deviceType?.replace(/SSP/g,'Ditto Receiver'),
                                    platform: x.platform?.replace(/windows/g, 'Windows').replace(/tvos/g, 'tvOS').replace(/other/g, 'Other').replace(/chromecast/g, 'Chromecast').replace(/atvlegacy/g, 'ATV 2/3')
                                };
                            });
                        }
                        else {
                            this.roomSearchResults[expandedRowIndex].roomDetails.receivers = [];
                            this.roomSearchResults[expandedRowIndex].roomDetails.hasSSPReceiver = false;
                        }
                    }

                    const roomOnboardingDetails = await onboardingState.fetchActiveOnboardByRoomId(row.id);
                    this.roomSearchResults[expandedRowIndex].roomDetails.isOnboarding = roomOnboardingDetails.success && roomOnboardingDetails.data && roomOnboardingDetails.data.length > 0;
        
                    if(this.roomSearchResults[expandedRowIndex].roomDetails.isOnboarding) {
                        const userDetails = this.users.find((x: any) => x.id == roomOnboardingDetails.data[0].user.id);
                        if(userDetails) {
                            this.roomSearchResults[expandedRowIndex].roomDetails.onboardingUser = {
                                id: userDetails.id,
                                name: userDetails.name
                            }
                        }
                    }
                }
                this.roomSearchResults[expandedRowIndex].expandRoomLoading = false;
            }
        },

        formatTierRestriction(restriction: number): string {
            if(restriction == 0) {
                return 'unlimited'
            }

            if(restriction == -1) {
                return 'no new'
            }

            return restriction ? restriction.toString() : '';
        },

        checkFeature(id: string): boolean {
            if(id && this.featuresUsed) {
                const check = this.featuresUsed.find((x: any) => x.id == id);
                if(check){
                    return true;
                }
                return false;
            }
            return false;
        },

        tableRowClassName(obj: any) {
            if((this.featureChangeList.length > 0 && this.featureChangeList.includes(obj.row.id))
            || (this.restrictionsChangeList.length > 0 && this.restrictionsChangeList.includes(obj.row.id))) {
                return 'danger-row';
            }

            return '';
        }, 

        onRefundClick() {
            this.isRefundModalVisibile = true;
        },

        async onRefund() {
            if(!this.refund.refundAmount) { return; }

            this.saving = true;

            if(!await this.validatRefundForm()) return;

            const payload = {
                customerId: this.organization.billing.customer_id,
                organizationId: this.organization.id,
                refundAmount: this.refund.refundAmount
            }

            const refundResponse = await subscriptionState.refundSubscription(payload);

            if(!refundResponse.success) {
                (this as any).$message.error("Subscription refund failed", refundResponse.reason);
                this.saving = false;
                return;
            }

            this.refund.refundAmount = null;
            this.saving = false;
            this.isRefundModalVisibile = false;
        },

        getRoleName(id: string) {
            if(!roleState || !roleState.roles) return "";
            
            const role = roleState.roles.find((x:any) => x.id == id);
            
            return role && role.name ? role.name : '';
        },

        enableHigherResolutionSetting(setting: EnableRoomSettings): boolean {
            if(setting == EnableRoomSettings.true) {
                return true;
            }
            else if(setting == EnableRoomSettings.false) {
                return false;
            }
            else {
                return this.organization.enableHigherResolution ?? false;
            }
        },

        showSignageDateTimeSetting(setting: EnableRoomSettings): boolean {
            if(setting == EnableRoomSettings.true) {
                return true;
            }
            else if(setting == EnableRoomSettings.false) {
                return false;
            }
            else {
                return this.organization.showSignageDateTime ?? false;
            }
        },

        signageDateTimeLocationSetting(setting: EnableRoomLocationSettings): string {
            if(setting == EnableRoomLocationSettings.useOrgSetting) {
                switch(this.organization.signageDateTimeLocation) {
                    case 'topRight':
                        return 'Top Right';
                    case 'topLeft': 
                        return 'Top Left';
                    case 'bottomLeft':
                        return 'Bottom Left'
                    case 'bottomRight':
                        return 'Bottom Right';
                    default:
                        return 'Top Right';
                } 
            }
            else {
                return ({
                    0: 'Top Left',
                    1: 'Top Right',
                    2: 'Bottom Left',
                    3: 'Bottom Right'
                }[setting])
            }
        }
    },

    computed: {
        selectedTier(): ITier {
            const selectedTier = this.allTiers.find(x => x.id == this.organization.tierId);
            return selectedTier ? selectedTier : {} as ITier;
        },

        usedStorageToSize() {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let bytes = tierState.orgTierUsage.storageUsedBytes;

            if (bytes === 0) return '0'
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },

        orgLimitUsage(): any {
            const userLimit = !this.orgTier.restrictionUsers && this.orgTier.restrictionUsers != 0 ? 'NA' : this.orgTier.restrictionUsers == 0 ? 'Unlimited' : this.orgTier.restrictionUsers;
            const receiverLimit = !this.orgTier.restrictionReceivers && this.orgTier.restrictionReceivers != 0 ? 'NA' : this.orgTier.restrictionReceivers == 0 ? 'Unlimited' : this.orgTier.restrictionReceivers;
            const signageLimit = !this.orgTier.restrictionSignageItems && this.orgTier.restrictionSignageItems != 0 ? 'NA' : this.orgTier.restrictionSignageItems == 0 ? 'Unlimited' : this.orgTier.restrictionSignageItems;
            const subscriptionLimit = this.organization.billing.subscription_limit ? this.organization.billing.subscription_limit : 0;
            const storageLimit = !this.orgTier.restrictionStorageGBPerRec && subscriptionLimit != 0 ? 'NA' : subscriptionLimit == 0 ? 'Unlimited' : `${this.orgTier.restrictionStorageGBPerRec * subscriptionLimit} GB`;

            return [{
                id: 'restrictionUsers',
                limitName: 'Number of Users',
                limit: `${tierState.orgTierUsage.userCount}/${userLimit}`
            },
            {
                id: 'restrictionReceivers',
                limitName: 'Number of Receivers',
                limit: `${tierState.orgTierUsage.receiverCount}/${receiverLimit}`
            },
            {
                id: 'restrictionSignageItems',
                limitName: 'Number of Signage Items',
                limit: `${tierState.orgTierUsage.signageItemCount}/${signageLimit}`
            },
            {
                id: 'restrictionStorageGBPerRec',
                limitName: 'Storage',
                limit: `${this.usedStorageToSize}/${storageLimit}`
            }]
        },

        featuresUsed() {
            return tierState.orgTierUsage.featuresUsed;
        },

        userControlledFeatures() {
            return tierState.featuresList.filter((x: any) => x.userControlled == true && x.tiers.some((y: any) => y.id == this.currentPlanId));
        },

        currentPlanName(): string {
            const currentTier = this.allTiers.find((x: any) => x.id == this.currentPlanId);
            return currentTier ? currentTier.name : '';
        },

        totalChangeText(): string {
            let dayMessage = '';
            let totalMessage = '';
            if(this.newSubscriptionTotal.includes("-") 
                && moment(this.organization.billing.expiration_date).subtract(30, 'days') > moment()
                && this.organization.billing.billing_type == 'Stripe' 
                && (this.organization.billing.billingFrequency != this.currentBillingFrequency
                || this.organization.billing.subscription_limit != this.currentSubscriptionLimit
                || this.organization.tierId != this.currentPlanId)) {
                dayMessage = 'overrides the 30-day change window';
            }
            
            if(this.newSubscriptionTotal) {
                if(this.newSubscriptionTotal.includes("-")) {
                    totalMessage = `creates a refund available amount of ${this.newSubscriptionTotal.replace('-', '')}`;
                }
                else {
                    totalMessage = `causes an immediate charge amount of ${this.newSubscriptionTotal}`;
                }
            }

            if(dayMessage && totalMessage) {
                return `Saving changes ${dayMessage} and ${totalMessage}.`;
            }
            if(dayMessage && !totalMessage) {
                return `Saving changes ${dayMessage}.`;
            }
            if(!dayMessage && totalMessage) {
                return `Saving changes ${totalMessage}.`;
            }
            return '';
        },

        stripeCustomerUrl(): string {
            if(this.organization && this.organization.billing && this.organization.billing.customer_id) {
                return `${ process.env.VUE_APP_STRIPE_CUSTOMER_URL }${this.organization.billing.customer_id}`;
            }
            return '';
        },

        isPO(): boolean {
            return this.organization.billing.billing_type === 'PO';
        },

        showDaily(): boolean {
            const tier = this.allTiers.find((x: any) => x.id == this.organization.tierId);
            if(tier && tier.stripeProductId) {
                const hasDaily = subscriptionState.allSubscriptionPlans.some((x: any) => x.id == tier.stripeProductId && x.pricing.some((y: any) => y.recurring.interval == 'day'));
                return hasDaily;
            }
            return false;
        },

        hasAlerts(): boolean {
            return tierState.orgTier.emergencyAlertsEnabled;
        },

        hasSignage(): boolean {
            return tierState.orgTier.digitalSignageEnabled;
        },

        hasModeratorControls(): boolean {
            return tierState.orgTier.moderatorControlsEnabled;
        }
    },

    async created() {
        organizationState = getModule(OrganizationState);
        userState = getModule(UserState);
        roomState = getModule(RoomState);
        tierState = getModule(TierState);
        subscriptionState = getModule(SubscriptionState);
        roleState = getModule(RoleState);
        receiverState = getModule(ReceiverState);
        onboardingState = getModule(OnboardingState);

        const loadOrgsResponse = await organizationState.fetchOrganizations(this.$route.params.id as string);
        if(!loadOrgsResponse.success) {
            this.viewLoading = false;
            this.organization = {} as IOrganization;
            return;
        }
        this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
        this.ogBillingType = this.organization.billing.billing_type;

        await Promise.all([
            tierState.fetchAllTiersList(),
            tierState.fetchTier(this.organization.id),
            tierState.fetchTierUsage(this.organization.id),
            tierState.fetchAllFeaturesList(),
            roleState.fetchRoles(this.organization.id),
            subscriptionState.fetchSubscriptionPlans()
        ]);

        if(this.organization && this.organization.billing && this.organization.billing.customer_id) {
            const sub = await subscriptionState.fetchSuperSubscriptions(this.organization.billing.customer_id);

            if(sub.data) {
                this.currentSubscription = sub.data[0];
                
                if(this.currentSubscription && this.currentSubscription.latest_invoice) {
                    const inv = await subscriptionState.fetchInvoice(this.currentSubscription.latest_invoice);
                    this.latestInvoiceTotal = Number((inv.data.total/100).toFixed(2));
                }
               
            }
        }
        
        this.currentPlanId = this.organization.tierId;
        this.currentBillingFrequency = this.organization.billing.billingFrequency;
        this.currentSubscriptionLimit = this.organization.billing.subscription_limit;
        this.currentRefundAmount = this.organization.billing.stripeRefundAmount && Number(this.organization.billing.stripeRefundAmount.replace('$', '')) ? Number(this.organization.billing.stripeRefundAmount.replace('$', '')).toFixed(2) : Number(0.00).toFixed(2);
        this.allTiers = tierState.tiersList;
        this.orgTier = tierState.orgTier;
      
        this.viewLoading = false;
        const userSearchResults = await userState.searchUsers({pageIndex:1, pageSize: 1000, organizationId: this.$route.params.id as string})
        if(!userSearchResults || !userSearchResults.data || !userSearchResults.data.data) return;
        this.users = userSearchResults.data.data;
        this.usersLoading = true;

        await this.loadRooms();
        await this.loadReceivers();
    },

    components: {
        OrgUsageStatBarGraphs,
        RoomsImport,
        Icon   
    },

    watch: {
        "$route.params.id"(value) {
            if(value && this.$route.path.includes('admin/organizations')) {
                organizationState.fetchOrganizations(this.$route.params.id as string).then(()=> {
                    this.ogBillingType = this.organization.billing.billing_type
                    this.organization = Object.assign({}, organizationState.getOrganizationById(this.$route.params.id as string));
                    this.ogBillingType = this.organization.billing.billing_type
                    userState.searchUsers({pageIndex:1, pageSize: 1000, organizationId: this.$route.params.id as string}).then(userSearchResults => {
                        if(!userSearchResults || !userSearchResults.data || !userSearchResults.data.data) return;
                        this.users = userSearchResults.data.data;
                    });
                }).catch(() => {
                    this.viewLoading = false;
                    this.organization = {} as IOrganization;
                });
            }
        },
        organization: {
            handler (e) {
                const origOrg = organizationState.getOrganizationById(this.$route.params.id as string);

                PageDirtyService.monitorObjectSet([{defaultObject: origOrg, mutatedObject: e}]);
            },
            deep: true
        }
    }
})
