import {
    Action,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';

import store from '..';
import { getAuthInstance } from '../../auth';
import axios from 'axios';
import { ISqStoreActionResponse } from '../interfaces/ISqStoreActionResponse';
import { IRole, RoleApplicationPermission } from '../interfaces/IRole';

export interface IRoleState {
    readonly userRole: IRole | null;
    readonly otherUserRole: IRole | null;
    readonly roles: IRole[] | null;
    readonly isAdmin: boolean;
    readonly isSystemRole: boolean;
    readonly hasSuperAccess: boolean;
    readonly hasReceiversAccess: boolean;
    readonly hasRoomsAccess: boolean;
    readonly hasLocationsAccess: boolean;
    readonly hasSignageAccess: boolean;
    readonly hasAlertsAccess: boolean;
    readonly hasOrganizationAccess: boolean;
    readonly hasBillingAccess: boolean;
    readonly hasUsersAccess: boolean;
    readonly otherUserHasReceiversAccess: boolean;
    readonly otherUserHasRoomsAccess: boolean;

    fetchUserRole(): Promise<ISqStoreActionResponse<IRole | null>>;
    fetchUserRoleByUserId(id: string): Promise<ISqStoreActionResponse<IRole | null>>;
    fetchRoles(orgId?:string): Promise<ISqStoreActionResponse<IRole[]>>;
    createRole(role: IRole): Promise<ISqStoreActionResponse<IRole>>;
    updateRole(role: IRole): Promise<ISqStoreActionResponse<IRole>>;
    deleteRole(id: string): Promise<ISqStoreActionResponse<void>>;
}

@Module({ dynamic: true, store, name: 'groups' })
export default class RoleState extends VuexModule implements IRoleState {
    // THESE HAVE TO BE NAMED UNIQUELY
    // THE WAY STATE WORKS IF YOU ALTER THIESE VIA CONTEXT 
    // ANY NAMED THE SAME WAY WILL CHANGE ALSO
    private _userRole: IRole = null as any;
    private _roles: IRole[] = null as any;
    private _otherUserRole: IRole = null as any;
    

    //#region GETTERS
    get userRole(): IRole | null {
        if(!this._userRole) return null;
        return this._userRole;
    }

    get roles(): IRole[] | null {
        if(!this._roles) return null;
        return this._roles;
    }

    get isAdmin(): boolean {
        if(this.userRole && this.userRole.name.toLowerCase() == 'admin') { 
            return true; 
        }
        return false;
    }

    get isSystemRole(): boolean {
        if(this.userRole && (this.userRole.name.toLowerCase() == 'admin' ||this.userRole.name.toLowerCase() == 'user')) { 
            return true; 
        }
        return false;
    }

    get hasSuperAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.super && this.userRole.application.super == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasReceiversAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.receivers && this.userRole.application.receivers == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasRoomsAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.rooms && this.userRole.application.rooms == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasLocationsAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.locations && this.userRole.application.locations == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasSignageAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.signage && this.userRole.application.signage == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasAlertsAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.alerts && this.userRole.application.alerts == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasOrganizationAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.organization && this.userRole.application.organization == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasBillingAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.billing && this.userRole.application.billing == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get hasUsersAccess(): boolean {
        if(this.userRole && this.userRole.application && this.userRole.application.users && this.userRole.application.users == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get otherUserRole(): IRole | null {
        if(!this._otherUserRole) return null;
        return this._otherUserRole;
    }

    get otherUserHasReceiversAccess(): boolean {
        if(this.otherUserRole && this.otherUserRole.application && this.otherUserRole.application.receivers && this.otherUserRole.application.receivers == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }

    get otherUserHasRoomsAccess(): boolean {
        if(this.otherUserRole && this.otherUserRole.application && this.otherUserRole.application.rooms && this.otherUserRole.application.rooms == RoleApplicationPermission.write) { 
            return true; 
        }
        return false;
    }
    //#endregion


    //#region ACTIONS

    @Action
    async fetchRoles(orgId: string): Promise<ISqStoreActionResponse<IRole[]>> {
        try {
            const auth = await getAuthInstance();
            // verify that were completely logged in before we make any requests!
            if(!auth.auth0user['https://goditto.com/ditto.user_id'] || !auth.dittoUser.id) {
                return {success: false}
            }

            const authHeader = await auth.getAxiosHeader();

            const organizationId = orgId && orgId.length > 0 ? `?organizationId=${orgId}` : '';

            const fetchRoleUrl = `${process.env.VUE_APP_API_HOST}/api/roles/${organizationId}`;
            const axiosResponse = await axios.get(fetchRoleUrl, authHeader);
            
            this.context.commit('setRoles', axiosResponse.data);

            const returnData = this.userRole ? this.userRole as IRole : null;
            
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Role Store', e);
        }

        return { success: false, reason: 'Error fetching roles.' };
    }

    @Action
    async fetchUserRole(): Promise<ISqStoreActionResponse<IRole | null>> {
        try {
            if(this.userRole) return { success: true, data: this.userRole as any};
            
            const auth = await getAuthInstance();
            // verify that were completely logged in before we make any requests!
            if(!auth.auth0user['https://goditto.com/ditto.user_id'] || !auth.dittoUser.id) {
                return {success: false}
            }

            const authHeader = await auth.getAxiosHeader();

            const fetchUserRoleUrl = `${process.env.VUE_APP_API_HOST}/api/roles/userrole`;
            const axiosResponse = await axios.get(fetchUserRoleUrl, authHeader);
            
            this.context.commit('setUserRole', axiosResponse.data);

            const returnData = this.userRole ? this.userRole as IRole : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Role Store', e);
        }

        return { success: false, reason: 'Error fetching role.' };
    }

    @Action
    async fetchUserRoleByUserId(id: string): Promise<ISqStoreActionResponse<IRole | null>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const fetchUserRoleUrl = `${process.env.VUE_APP_API_HOST}/api/roles/userrole/${id}`;
            const axiosResponse = await axios.get(fetchUserRoleUrl, authHeader);
            
            this.context.commit('setOtherUserRole', axiosResponse.data);

            const returnData = this.userRole ? this.userRole as IRole : null;
            return { success: true, data: returnData as any};
        }
        catch(e:any) {
            console.error('Role Store', e);
        }

        return { success: false, reason: 'Error fetching role by user id.' };
    }

    @Action
    async createRole(role: IRole): Promise<ISqStoreActionResponse<IRole>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const createRoleUrl = `${ process.env.VUE_APP_API_HOST }/api/roles/`;
            
            const axiosResponse = await axios.post(createRoleUrl, role, authHeader);
            
            this.context.commit('setRoles', [...this._roles, axiosResponse.data]);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Roles Store Error:', e);
        }

        return { success: false, reason: 'Error creating role.'};
    }

    @Action
    async updateRole(role: IRole): Promise<ISqStoreActionResponse<IRole>> {
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const updateRoleUrl = `${ process.env.VUE_APP_API_HOST }/api/roles/${role.id}`;
            const axiosResponse = await axios.put(updateRoleUrl, role, authHeader);

            const mutatedRoles = [
                ...this._roles.filter(x=>x.id !== role.id),
                axiosResponse.data
            ];

            this.context.commit('setRoles', mutatedRoles);

            return { success: true, data: axiosResponse.data };
        }
        catch(e:any) {
            console.error('Roles Store Error:', e);
        }

        return { success: false, reason: 'Error creating role.'};
    }

    @Action
    async deleteRole(id: string): Promise<ISqStoreActionResponse<void>> { 
        try {
            const authHeader = await getAuthInstance().getAxiosHeader();

            const deleteRoleUrl = `${ process.env.VUE_APP_API_HOST }/api/roles/${id}`;
            await axios.delete(deleteRoleUrl, authHeader);
            
            const mutatedRoles = [
                ...this._roles.filter(x=> x.id !== id)
            ];

            this.context.commit('setRoles', mutatedRoles);
            
            return { success: true };
        }
        catch(e:any) {
            console.error('Roles Store Error:', e);
        }

        return { success: false, reason: 'Error deleting role.'};
    }
    //#endregion


    //#region MUTATIONS
    @Mutation
    async setUserRole(role: IRole): Promise<void> {
        this._userRole = role;
    }

    @Mutation
    async setRoles(roles: IRole[]): Promise<void> {
        this._roles = roles;
    }

    @Mutation
    async setOtherUserRole(role: IRole): Promise<void> {
        this._otherUserRole = role;
    }
    //#endregion
}